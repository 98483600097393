import logo from "./img/logo.svg";
import icon from "./img/icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { useContext } from "react";
import { Theme } from "../../Contexts/Theme";
import outIcon from "./img/out.svg";
import icon1 from "./img/icon1.svg";

import icon2 from "./img/icon2.svg";

import icon3 from "./img/icon3.svg";

import icon4 from "./img/icon4.svg";

import icon5 from "./img/icon5.svg";

import icon6 from "./img/icon6.svg";
import iconProfile from "./img/iconProfile.svg";
import icon7 from "./img/icon7.svg";
import close from "./img/close.svg";
import { routes } from "../../routes";
import { BusketV } from "../../Contexts/Busket";
import { useEffect } from "react";
import { DataMain } from "../../Contexts/Data";
import { User } from "../../Contexts/User";
import { id } from "../../Consts/Const";
export default function NavBar({ nav, isNav }) {
  const location = useLocation();
  const [opt, setOpts] = useContext(Theme);
  const [auth, setAuth] = useContext(User);
  const [scrolling, setScrolling] = useContext(DataMain);
  const Navigate = useNavigate();
  const [busket, setBusket] = useContext(BusketV);
  const [data, setData] = useContext(DataMain);
  useEffect(() => {
    document.body.clientWidth < 640 && isNav(false);
  }, [location.pathname]);
  console.log(auth);
  return (
    <div
      className="nav"
      style={{
        //background: opt.background,
        width: nav ? "220px" : document.body.clientWidth > 640 ? "60px" : "0px",
        minWidth: nav
          ? "220px"
          : document.body.clientWidth > 640
          ? "60px"
          : "0px",
        paddingLeft: nav ? "20px" : "10px",
        padding: !nav && document.body.clientWidth < 640 && "0",
      }}
    >
      <img src={data && data.userInfo ? data.userInfo.Logo : ""} alt="" />
      {document.body.clientWidth < 640 && (
        <img src={close} onClick={() => isNav(!nav)} className="crest" />
      )}
      <div>
        {auth && auth.Phone && auth.Phone.length > 0 && (
          <div className="account" onClick={() => Navigate(routes.profile)}>
            <img
              src={
                auth && auth.Photo && auth.Photo.length > 0
                  ? auth.Photo
                  : iconProfile
              }
              alt=""
              style={
                !nav
                  ? { width: "40px", height: "40px", marginLeft: "5px" }
                  : { width: "50px", height: "50px" }
              }
            />
            <div>
              <p>{auth && auth.FIO.split(" ")[0]}</p>
              <span>Новичок</span>
            </div>
          </div>
        )}
        {auth.length == 0 && (
          <Link to={routes.login}>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 7.00024C13.6075 7.00024 8 12.6078 8 19.5002C8 26.3922 13.6095 32.0002 20.5 32.0002C27.4042 32.0002 33 26.3781 33 19.5002C33 12.6078 27.3925 7.00024 20.5 7.00024ZM20.5 30.3698C17.9545 30.3698 15.6109 29.4899 13.7564 28.0188C14.9046 25.3404 17.5484 23.5764 20.5 23.5764C23.4521 23.5764 26.0963 25.34 27.2447 28.0179C25.3901 29.4896 23.046 30.3698 20.5 30.3698ZM16.4239 17.8698C16.4239 15.6223 18.2524 13.7937 20.5 13.7937C22.7476 13.7937 24.5761 15.6223 24.5761 17.8698C24.5761 20.1174 22.7476 21.9459 20.5 21.9459C18.2524 21.9459 16.4239 20.1174 16.4239 17.8698ZM28.499 26.8516C27.4914 24.8659 25.7879 23.3472 23.7531 22.5555C25.2348 21.5237 26.2065 19.8083 26.2065 17.8698C26.2065 14.7232 23.6466 12.1633 20.5 12.1633C17.3534 12.1633 14.7935 14.7232 14.7935 17.8698C14.7935 19.8083 15.7653 21.5239 17.2472 22.5557C15.2126 23.3476 13.5095 24.8667 12.5021 26.8527C10.7202 24.916 9.63042 22.3331 9.63042 19.5002C9.63042 13.5068 14.5065 8.63066 20.5 8.63066C26.4935 8.63066 31.3696 13.5068 31.3696 19.5002C31.3696 22.3326 30.2802 24.915 28.499 26.8516Z"
                fill="var(--color-text-opacity)"
              />
            </svg>

            <p
              className="authB"
              style={
                location.pathname == routes.login
                  ? { fontWeight: "600", color: "var(--color-text-two)" }
                  : {}
              }
            >
              Ваш профиль
            </p>
          </Link>
        )}
        <Link
          to={routes.main}
          onClick={() => setScrolling((scroll) => ({ ...scroll, scrollY: 0 }))}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 24C17.85 24.63 18.885 25 20 25C21.115 25 22.15 24.63 23 24"
              stroke={
                location.pathname == routes.main
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M30 20.204V21.725C30 25.625 30 27.576 28.828 28.788C27.657 30 25.771 30 22 30H18C14.229 30 12.343 30 11.172 28.788C10 27.576 10 25.626 10 21.725V20.204C10 17.915 10 16.771 10.52 15.823C11.038 14.874 11.987 14.286 13.884 13.108L15.884 11.867C17.889 10.622 18.892 10 20 10C21.108 10 22.11 10.622 24.116 11.867L26.116 13.108C28.013 14.286 28.962 14.874 29.481 15.823"
              stroke={
                location.pathname == routes.main
                  ? "var(--color-text-two)"
                  : "var(--color-text-opacity)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <p
            style={
              location.pathname == routes.main
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Главная
          </p>
        </Link>
        <Link
          to={routes.services}
          onClick={() => setScrolling((scroll) => ({ ...scroll, scrollY: 0 }))}
        >
          <img
            src={icon2}
            alt=""
            style={
              location.pathname == routes.services
                ? {
                    filter:
                      "invert(54%) sepia(29%) saturate(5791%) hue-rotate(0deg) brightness(103%) contrast(105%)",
                  }
                : {}
            }
          />
          <p
            style={
              location.pathname == routes.services
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Каталог
          </p>
        </Link>
        <Link to={routes.basket}>
          <img
            src={icon3}
            alt=""
            style={
              location.pathname == routes.basket
                ? {
                    filter:
                      "invert(54%) sepia(29%) saturate(5791%) hue-rotate(0deg) brightness(103%) contrast(105%)",
                  }
                : {}
            }
          />
          <p
            style={
              location.pathname == routes.basket
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Корзина
          </p>

          {busket && busket.length > 0 && (
            <span
              className="numLength"
              style={!nav ? { transform: "translate(-110px,-5px)" } : {}}
            >
              {busket.length}
            </span>
          )}
        </Link>
        <Link to={routes.about}>
          <img
            src={icon4}
            alt=""
            style={
              location.pathname == routes.about
                ? {
                    filter:
                      "invert(54%) sepia(29%) saturate(5791%) hue-rotate(0deg) brightness(103%) contrast(105%)",
                  }
                : {}
            }
          />
          <p
            style={
              location.pathname == routes.about
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            О компании
          </p>
        </Link>
        <Link to={routes.contacts}>
          <img
            src={icon5}
            alt=""
            style={
              location.pathname == routes.contacts
                ? {
                    filter:
                      "invert(54%) sepia(29%) saturate(5791%) hue-rotate(0deg) brightness(103%) contrast(105%)",
                  }
                : {}
            }
          />
          <p
            style={
              location.pathname == routes.contacts
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Контакты
          </p>
        </Link>
        <Link to={routes.documents}>
          <img
            src={icon7}
            alt=""
            style={
              location.pathname == routes.documents
                ? {
                    filter:
                      "invert(54%) sepia(29%) saturate(5791%) hue-rotate(0deg) brightness(103%) contrast(105%)",
                  }
                : {}
            }
          />
          <p
            style={
              location.pathname == routes.documents
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Документы
          </p>
        </Link>
        <Link to={routes.chat}>
          <img
            src={icon6}
            alt=""
            style={
              location.pathname == routes.chat
                ? {
                    filter:
                      "invert(54%) sepia(29%) saturate(5791%) hue-rotate(0deg) brightness(103%) contrast(105%)",
                  }
                : {}
            }
          />
          <p
            style={
              location.pathname == routes.chat
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            On-line Чат
          </p>
        </Link>
      </div>
      <div className="collapse" onClick={() => isNav(!nav)}>
        <img
          src={outIcon}
          style={!nav ? { transform: "rotate(180deg)" } : {}}
          alt=""
        />
        <p>Свернуть</p>
      </div>
    </div>
  );
}
