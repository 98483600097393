import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { routes } from "./routes";
import Services from "./Pages/Services";
import { Theme } from "./Contexts/Theme";
import { useContext, useRef, useState } from "react";
import { whiteTheme } from "./Consts/Theme";
import NavBar from "./Components/NavBar";
import { Nav } from "./Contexts/Nav";
import Main from "./Pages/Main";
import Busket from "./Pages/Busket";
import RightPage from "./Components/RightPage";
import lg from "./logo.svg";
import Contacts from "./Pages/Contacts";
import Company from "./Pages/Company";
import logo from "./logo.png";
import burger from "./burger.svg";
import { useEffect } from "react";
import { BusketV } from "./Contexts/Busket";
import CardPage from "./Pages/CardPage";
import { DataMain } from "./Contexts/Data";
import { id } from "./Consts/Const";
import { Fade, Zoom } from "react-reveal";
import NavMobile from "./Components/NavMobile";
import ThemeCont from "./Components/Theme";
import Documents from "./Pages/Documents";
import Chat from "./Pages/Chat";
import "react-notifications-component/dist/theme.css";
import { ReactNotifications } from "react-notifications-component";
import { StoryItems } from "./Contexts/Story";
import { Favourite } from "./Contexts/Favourite";
import Login from "./Pages/Login";
import { User } from "./Contexts/User";
import Profile from "./Pages/Profile";
import Slider from "./Pages/Slider";
import Footer from "./Components/Footer/Footer";
import { Registration } from "./Pages/Registration";
import { getThemes, getUserInfo } from "./Api/ApiGet";
import ArModel from "./Pages/ArModel";
function App() {
  const [data, setData] = useState({
    dataM: [],
    stocks: [],
    hots: [],
    scrollY: 0,
    userInfo: [],
  });
  const [whiteThem, setWhiteTheme] = useState("");
  const [blackThem, setBlackTheme] = useState("");
  const [user, setUser] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : []
  );
  const [favourites, setFavourites] = useState(
    localStorage.favourites ? JSON.parse(localStorage.favourites) : []
  );
  const [search, setSearch] = useState("");
  const Gradient = useRef(null);
  const location = useLocation();
  useEffect(() => {
    localStorage.favourites = JSON.stringify(favourites);
  }, [favourites]);
  function getStocks() {
    let formData = new FormData();
    formData.append("ID", id);
    fetch("https://comeback-team.ru/getStocks.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData((prevState) => ({
          ...prevState,
          stocks: data,
        }));
      });
  }
  useEffect(() => {
    if (
      location.pathname != routes.services &&
      location.pathname != routes.main &&
      !location.pathname.includes("card")
    ) {
      setData((scroll) => ({ ...scroll, scrollY: 0 }));
    }
  }, [location.pathname]);

  const getHots = () => {
    let formData = new FormData();
    formData.append("ID", id);
    fetch("https://comeback-team.ru/cardsApi/getHots.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData((prevState) => ({
          ...prevState,
          hots: data,
        }));
      });
  };
  const [storySearch, setStorySearch] = useState(
    localStorage.story ? JSON.parse(localStorage.story) : []
  );
  useEffect(() => {
    localStorage.story = JSON.stringify(storySearch);
  }, [storySearch]);
  function getData() {
    let formData = new FormData();
    formData.append("idOwner", id);
    fetch("https://comeback-team.ru/admin/Tovar.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData((prevState) => ({
          ...prevState,
          dataM: data,
        }));
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    setSearch("");
  }, [location.pathname]);
  const [theme, setTheme] = useState("white");
  useEffect(() => {
    getThemes().then((data) => {
      let thm = data[0].params;
      setWhiteTheme(data[0].params);
      setBlackTheme(data[1].params);
      document.documentElement.style.cssText = thm;
    });
    getData();
    getStocks();
    getHots();
  }, []);

  const [nav, isNav] = useState(false);
  const [busket, setBusket] = useState(
    localStorage.busket ? JSON.parse(localStorage.busket) : []
  );
  useEffect(() => {
    if (theme == "white") {
      document.documentElement.style.cssText = whiteThem;
    } else {
      document.documentElement.style.cssText = blackThem;
    }
  }, [theme]);
  useEffect(() => {
    localStorage.busket = JSON.stringify(busket);
  }, [busket]);
  /*   useEffect(() => {
    if (Gradient && Gradient.current && document.body.clientWidth > 640) {
      document.addEventListener("mousemove", (e) => {
        Gradient.current.style.backgroundImage = `radial-gradient(circle at ${e.pageX}px ${e.pageY}px, ${theme.gradient})
      `;
      });
      return document.removeEventListener("mousemove", (e) => {
        Gradient.current.style.backgroundImage = `radial-gradient(circle at ${e.pageX}px ${e.pageY}px, ${theme.gradient})
      `;
      });
    }
  }, [theme]); */
  useEffect(() => {
    if (location.pathname != routes.slider) {
      setTimeout(() => {
        document.getElementsByClassName("preloader")[0].style.transform =
          "translateY(-150vh)";
      }, [2000]);
    }
  }, []);
  useEffect(() => {
    getUserInfo().then((data) => {
      setData((prevState) => ({
        ...prevState,
        userInfo: data,
      }));
    });
  }, []);
  const Navigate = useNavigate();
  return (
    <div className="App">
      {document.body.clientWidth < 640 && (
        <img className="noWatch" src="./nop.png"></img>
      )}
      {location.pathname != routes.slider && (
        <div className="preloader">
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <Zoom top>
            <img className="lgs" src={lg} />{" "}
          </Zoom>
          <Zoom top>
            <p className="animate__bounceInDown">Интернет-витрина.рф</p>
          </Zoom>
          <Zoom bottom>
            <span>Сэкономил - значит заработал!</span>
          </Zoom>
        </div>
      )}
      <ReactNotifications />
      <User.Provider value={[user, setUser]}>
        <Theme.Provider value={[theme, setTheme]}>
          <Favourite.Provider value={[favourites, setFavourites]}>
            <StoryItems.Provider value={[storySearch, setStorySearch]}>
              <Nav.Provider value={[nav, isNav]}>
                <DataMain.Provider value={[data, setData]}>
                  <BusketV.Provider value={[busket, setBusket]}>
                    <div
                      className="grad"
                      id="grad"
                      ref={Gradient}
                      style={{
                        backgroundImage: "var(--background-app)",
                        width: "100%",
                        paddingBottom:
                          location.pathname != routes.login &&
                          location.pathname != routes.registration
                            ? "20vw"
                            : "",
                      }}
                    >
                      {document.body.clientWidth < 640 && (
                        <div className="mobileHead">
                          <img
                            src={logo}
                            className="loge"
                            onClick={() => Navigate(routes.main)}
                          />
                          <a href="tel:+7 933 444 5566">+7 933 444 5566</a>
                          <ThemeCont></ThemeCont>
                        </div>
                      )}

                      {document.body.clientWidth > 640 &&
                        location.pathname != routes.login &&
                        location.pathname != routes.slider &&
                        location.pathname != routes.registration && (
                          <NavBar nav={nav} isNav={isNav}></NavBar>
                        )}
                      {location.pathname != routes.login &&
                        location.pathname != routes.slider &&
                        location.pathname != routes.registration &&
                        document.body.clientWidth < 640 && (
                          <NavMobile></NavMobile>
                        )}
                      <Routes>
                        <Route
                          path={routes.services}
                          element={
                            <Services setSearch={setSearch} search={search} />
                          }
                        />
                        <Route
                          path={routes.main}
                          element={
                            <Main
                              nav={nav}
                              isNav={isNav}
                              setSearch={setSearch}
                              search={search}
                            />
                          }
                        />
                        <Route path={routes.model} element={<ArModel />} />
                        <Route path={routes.basket} element={<Busket />} />

                        <Route
                          path={routes.documents}
                          element={<Documents />}
                        />
                        <Route path={routes.contacts} element={<Contacts />} />
                        <Route path={routes.login} element={<Login />} />
                        <Route
                          path={routes.registration}
                          element={<Registration />}
                        />
                        <Route path={"card/:myParam"} element={<CardPage />} />
                        <Route path={routes.about} element={<Company />} />
                        <Route path={routes.chat} element={<Chat />} />
                        <Route
                          path={routes.profile}
                          element={
                            <Profile setSearch={setSearch} search={search} />
                          }
                        />
                      </Routes>
                    </div>
                  </BusketV.Provider>
                </DataMain.Provider>
              </Nav.Provider>
            </StoryItems.Provider>
          </Favourite.Provider>
        </Theme.Provider>
      </User.Provider>
    </div>
  );
}

export default App;
