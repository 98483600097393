import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "./style.css";
import test from "./1.png";
import i2 from "./2.png";
import i3 from "./3.png";
import gal from "./gal.svg";
import { Pagination } from "swiper/modules";
import soc from "./soc.svg";
import { useContext, useRef, useState } from "react";
import vk from "./vk.svg";
import tg from "./tg.svg";
import arrow from "./arrow.svg";
import prom from "./prom.svg";
import { Theme } from "../../Contexts/Theme";
import nig from "./n.svg";
import tur from "./tur.png";
import turT from "./turtxt.svg";
import playB from "./play.svg";
import YouTube from "react-youtube";
import ListRightPage from "../ListRightPage";
import { Store } from "react-notifications-component";
import { DataMain } from "../../Contexts/Data";

export default function RightPage() {
  const promo = useRef(null);
  const [info, setInfo] = useContext(DataMain);
  function copy() {
    promo.current.select();
    document.execCommand("Copy");
    Store.addNotification({
      title: "Промокод:",
      message: `Промокод ${promo.current.value} успешно скопирован`,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 2500,
        onScreen: true,
      },
    });
  }
  const [theme, setTheme] = useContext(Theme);
  const [slide, setSlide] = useState(true);
  return (
    <div
      className="rightBody"
      style={
        info && info.userInfo && info.userInfo.RightPage == "0"
          ? { display: "none" }
          : {}
      }
    >
      <input
        type="text"
        id="prom"
        ref={promo}
        value={"XFASHION"}
        style={{ position: "absolute", top: "-100vw", right: "-100vw" }}
      />
      <div className="counters">
        <div
          onClick={() => setSlide(true)}
          style={
            slide ? { background: "var(--color-text-two)", color: "white" } : {}
          }
        >
          1
        </div>
        <div
          onClick={() => setSlide(false)}
          style={
            !slide
              ? { background: "var(--color-text-two)", color: "white" }
              : {}
          }
        >
          2
        </div>
      </div>
      <div
        className="firstBodyRight"
        style={{ transform: slide ? "translateX(0)" : "translateX(-19.5vw)" }}
      >
        <Swiper
          grabCursor={true}
          effect={"creative"}
          style={{ boxShadow: theme.shadowCard }}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          pagination={true}
          modules={[EffectCreative, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="sl">
              <img src={test} alt="" />

              <label
                htmlFor="prom"
                onClick={(e) => {
                  e.preventDefault();
                  copy();
                }}
              >
                <img src={prom} className="prom" alt="" />
              </label>
              <p>Распродажа</p>
              <span>10% скидка по промокоду до 31 марта</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="sl">
              <img src={i2} alt="" />
              <label
                htmlFor="prom"
                onClick={(e) => {
                  e.preventDefault();
                  copy();
                }}
              >
                <img src={prom} className="prom" alt="" />
              </label>
              <p>Распродажа</p>
              <span>10% скидка по промокоду до 31 марта</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="sl">
              <img src={i3} alt="" />
              <label
                htmlFor="prom"
                onClick={(e) => {
                  e.preventDefault();
                  copy();
                }}
              >
                <img src={prom} className="prom" alt="" />
              </label>
              <p>Распродажа</p>
              <span>10% скидка по промокоду до 31 марта</span>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="social" style={{ boxShadow: theme.shadowCard }}>
          <div className="msandtels">
            <svg
              onClick={() =>
                window.open(info && info.userInfo && info.userInfo.Wt, "_blank")
              }
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="40"
                height="40"
                rx="5"
                fill="url(#paint0_linear_1095_15478)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M28.6489 11.3425C26.4868 9.18818 23.6114 8.00126 20.5481 8C14.2359 8 9.0986 13.1125 9.09606 19.3963C9.09521 21.405 9.62248 23.3658 10.6247 25.0942L9 31L15.0709 29.4151C16.7437 30.3232 18.6269 30.8018 20.5434 30.8023H20.5482C26.8598 30.8023 31.9976 25.6894 32 19.4054C32.0013 16.36 30.8112 13.4966 28.6489 11.3425ZM20.5481 28.8776H20.5442C18.8362 28.8769 17.1612 28.4201 15.6996 27.5572L15.3522 27.3518L11.7496 28.2923L12.7112 24.7967L12.4848 24.4383C11.532 22.9301 11.0288 21.1868 11.0297 19.397C11.0316 14.1741 15.3017 9.9249 20.5519 9.9249C23.0943 9.92574 25.4842 10.9123 27.2813 12.7029C29.0783 14.4935 30.0674 16.8735 30.0665 19.4047C30.0643 24.628 25.7945 28.8776 20.5481 28.8776ZM25.7691 21.7829C25.4831 21.6402 24.0762 20.9515 23.8138 20.8564C23.5517 20.7613 23.3608 20.714 23.1702 20.999C22.9793 21.284 22.4311 21.9255 22.264 22.1154C22.097 22.3055 21.9303 22.3294 21.6441 22.1867C21.3579 22.0443 20.436 21.7434 19.3429 20.7733C18.4924 20.0181 17.9181 19.0856 17.7511 18.8006C17.5844 18.5154 17.7497 18.3761 17.8766 18.2194C18.1864 17.8366 18.4966 17.4353 18.5919 17.2453C18.6874 17.0553 18.6396 16.8889 18.568 16.7464C18.4966 16.6039 17.9243 15.2022 17.6859 14.6319C17.4535 14.0768 17.2178 14.1518 17.042 14.1431C16.8753 14.1348 16.6844 14.1331 16.4936 14.1331C16.3029 14.1331 15.9928 14.2043 15.7305 14.4895C15.4683 14.7746 14.7291 15.4635 14.7291 16.8652C14.7291 18.2669 15.7545 19.621 15.8975 19.8111C16.0405 20.0012 17.9153 22.8776 20.7856 24.1109C21.4683 24.4046 22.0013 24.5797 22.4169 24.7109C23.1025 24.9277 23.7261 24.8971 24.2192 24.8238C24.7691 24.742 25.912 24.1348 26.1507 23.4697C26.3891 22.8044 26.3891 22.2343 26.3174 22.1154C26.246 21.9967 26.0552 21.9255 25.7691 21.7829Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1095_15478"
                  x1="-6.93642"
                  y1="-6.66667"
                  x2="30.2096"
                  y2="47.7378"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#6BD075" />
                  <stop offset="1" stop-color="#17A525" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              onClick={() =>
                window.open(info && info.userInfo && info.userInfo.Tg, "_blank")
              }
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="40"
                height="40"
                rx="5"
                fill="url(#paint0_linear_1095_15481)"
              />
              <path
                d="M9.0465 20.2699L14.4135 22.0927L27.1555 14.3029C27.3402 14.1899 27.5294 14.4408 27.3701 14.5874L17.7235 23.4666L17.3648 28.4376C17.3375 28.8158 17.793 29.0263 18.0633 28.7605L21.0335 25.8398L26.4632 29.9502C27.0485 30.3933 27.896 30.081 28.0539 29.3641L31.8346 12.1975C32.0503 11.2182 31.0907 10.3918 30.1542 10.7504L9.01919 18.8436C8.35619 19.0975 8.37425 20.0417 9.0465 20.2699Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1095_15481"
                  x1="-6.93642"
                  y1="-6.66667"
                  x2="30.2096"
                  y2="47.7378"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#ABCEF9" />
                  <stop offset="0.532619" stop-color="#2EA6EA" />
                  <stop offset="1" stop-color="#006D9B" />
                </linearGradient>
              </defs>
            </svg>

            <a href={`tel:${info && info.userInfo && info.userInfo.TelWork}`}>
              {info && info.userInfo && info.userInfo.TelWork}
            </a>
          </div>
          <p>
            Время работы:{" "}
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3402 3.50036L10.1467 2.62981C9.9342 1.67463 9.82856 1.19672 9.4792 0.916719C9.13111 0.636719 8.64111 0.636719 7.66174 0.636719H6.65565C5.67565 0.636719 5.18565 0.636719 4.83756 0.916719C4.4882 1.19672 4.38256 1.67399 4.16938 2.62981L3.97656 3.50036M10.3402 10.5004L10.1467 11.3709C9.9342 12.3261 9.82856 12.804 9.4792 13.084C9.13047 13.364 8.64111 13.364 7.66174 13.364H6.65565C5.67629 13.364 5.18693 13.364 4.8382 13.084C4.48884 12.804 4.3832 12.3267 4.17002 11.3709L3.97656 10.5004"
                stroke="#988278"
                stroke-width="0.980372"
              />
              <path
                d="M2.54688 5.76348C2.84668 4.64512 3.54282 3.67387 4.5056 3.03069C5.46837 2.3875 6.63214 2.11624 7.78008 2.26744C8.92801 2.41864 9.98185 2.98199 10.7452 3.85253C11.5086 4.72307 11.9296 5.84145 11.9296 6.9993C11.9296 8.15716 11.5086 9.27553 10.7452 10.1461C9.98185 11.0166 8.92801 11.58 7.78008 11.7312C6.63214 11.8824 5.46837 11.6111 4.5056 10.9679C3.54282 10.3247 2.84668 9.35348 2.54688 8.23512"
                stroke="#988278"
                stroke-width="0.980372"
                stroke-linecap="round"
              />
              <path
                d="M7.15625 5.04102V6.99911L8.42898 8.22283"
                stroke="#988278"
                stroke-width="0.980372"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>{info && info.userInfo && info.userInfo.TimeWork}</span>
          </p>
          <div className="yandexMap">
            <img src="/svg/map.svg" alt="" />
            Яндекс Карты
          </div>
        </div>
        <div
          className="rightForm"
          style={{
            boxShadow: theme.shadowCard,
            background: "var(--background-form)",
          }}
        >
          <h4 style={{ color: "var(--color-text-one)" }}>
            Перезвоним вам за 1 минуту
          </h4>
          <p style={{ color: "var(--color-text-one)" }}>
            Сориентируем по любому вопросу
          </p>
          <input type="text" placeholder="Ваше имя" />
          <input type="tel" placeholder="Телефон*" />
          <div
            className="formButton"
            onClick={() => {
              Store.addNotification({
                title: "Заявка на звонок",
                message: "Ваш запрос получен, перезвоним в течение 5ти минут",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 2500,
                  onScreen: true,
                },
              });
            }}
          >
            Перезвоните мне
          </div>
          <div className="agree">
            <img src={gal} alt="" />
            <p>
              Согласен с <a href="#">Политикой конфиденциальности</a>
            </p>
          </div>
        </div>
      </div>
      <div
        className="secondBodyRight"
        style={{ transform: slide ? "translateX(0)" : "translateX(-19.5vw)" }}
      >
        <div className="tur">
          <img src={tur} className="turImg" alt="" />
          <img src={turT} className="turtxt" alt="" />
          <img src={playB} className="turPlay" alt="" />
        </div>
        <a
          className="socialLink"
          href="https://vk.com/club225084983"
          target="_blank"
        >
          <img src={vk} alt="" />
          <div>
            <p>
              RolexTeam
              <img src={arrow} alt="" />
            </p>
            <span>
              Задайте нам вопрос в личные сообщения <br /> и следить за акциями
              и новостями.
            </span>
          </div>
        </a>

        <a
          className="socialLink"
          href="https://t.me/internetvitrina_bot"
          target="_blank"
        >
          <img src={tg} alt="" />
          <div>
            <p>
              t.me/RolexTeam
              <img src={arrow} alt="" />
            </p>
            <span>
              Наши работы, акции, оперативные <br /> ответы на ваши возникшие
              вопросы
            </span>
          </div>
        </a>
        {slide == false && (
          <YouTube videoId="Xhu4eWfTkM0" className="youtubeRight" />
        )}
        <ListRightPage />
      </div>
    </div>
  );
}
